import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('JWT_TOKEN'),
    coder: tools.jsonParse(localStorage.getItem('LIBER_CODER')) || {},
    isDebug: !!localStorage.getItem('STORE_IS_DEBUG'),
    safeMode: !!localStorage.getItem('STORE_SAFE_MODE'),
    loading: false,
    message: ''
  },
  mutations: {
    setToken (state, token) {
      state.token = token
      localStorage.setItem('JWT_TOKEN', token)
    },
    setCoder (state, coder) {
      state.coder = coder
      localStorage.setItem('LIBER_CODER', JSON.stringify(coder))
    },
    isDebugToggle (state) {
      state.isDebug = !state.isDebug
      localStorage.setItem('STORE_IS_DEBUG', state.isDebug ? 'true' : '')
    },
    safeModeToggle (state) {
      state.safeMode = !state.safeMode
      localStorage.setItem('STORE_SAFE_MODE', state.safeMode ? 'true' : '')
    },
    setLoading (state, value) {
      state.loading = value
    },
    setMessage (state, value) {
      state.message = value
    }
  }
})
