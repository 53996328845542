<template>
  <div id="app">
    <router-view />
    <div class="console">
      <div class="console-message">{{ $store.state.message }}</div>
      <n-loading-bar :loading="$store.state.loading"></n-loading-bar>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss" scoped>
#app {
  min-height: 100%;
}
.console {
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  padding-bottom: 4px;
  pointer-events: none;
  &-message {
    color: #0e3481;
    font-size: 0.75rem;
    line-height: 1.25rem;
    padding: 0 0.5rem;
  }
}
</style>
