import SparkMD5 from 'spark-md5'
import store from '@/store'

async function getFileHash (file) {
  // Read in chunks of 2MB
  const chunkSize = 2 * 1024 * 1024

  const state = tools.promisify()
  let start = 0
  const spark = new SparkMD5.ArrayBuffer()
  const fileReader = new FileReader()

  fileReader.onload = (e) => {
    // Append array buffer
    spark.append(e.target.result)
    start += chunkSize
    if (start < file.size) {
      return readNextChunk()
    }
    // Compute hash
    state.resolve(spark.end())
  }

  fileReader.onerror = (error) => state.reject(error)

  function readNextChunk () {
    const end = Math.min(start + chunkSize, file.size)
    fileReader.readAsArrayBuffer(file.slice(start, end))
  }

  readNextChunk()

  return state.promise
}

export async function getFileHashName (file) {
  let ext = ''
  if (file.name.includes('.')) {
    ext = '.' + file.name.split('.').pop()
  }
  const hash = await getFileHash(file)
  return hash + ext
}

export async function getImageSize (file) {
  const state = tools.promisify()
  const image = new Image()
  const url = URL.createObjectURL(file)
  image.onload = (event) => {
    const { width, height } = event.target
    state.resolve({ width, height })
    URL.revokeObjectURL(url)
  }
  image.src = url
  return state.promise
}

export async function getBlobEncode (blob) {
  const { step, offset } = store.state.coder
  const arrayBuffer = await blob.arrayBuffer()
  const uint8Array = new Uint8Array(arrayBuffer)
  for (let i = 0; i < uint8Array.length; i += step) {
    uint8Array[i] = uint8Array[i] + offset % 256
  }
  const encode = new Blob([uint8Array.buffer], { type: blob.type })
  encode.name = blob.name
  return encode
}

export async function getBlobDecode (blob) {
  const { step, offset } = store.state.coder
  const arrayBuffer = await blob.arrayBuffer()
  const uint8Array = new Uint8Array(arrayBuffer)
  for (let i = 0; i < uint8Array.length; i += step) {
    uint8Array[i] = uint8Array[i] + 256 - offset % 256
  }
  const decode = new Blob([uint8Array.buffer], { type: blob.type })
  decode.name = blob.name
  return decode
}
//
// export async function getBlobCaesarEncode (blob) {
//   const { mixed } = store.state.coder
//   const text = await blob.text()
//   const textCaesar = Array.from(text)
//     .map(word => word.charCodeAt(0) + mixed)
//     .map(code => String.fromCharCode(code))
//     .join('')
//   const blobCaesar = new Blob([textCaesar], { type: blob.type })
//   blobCaesar.name = blob.name
//   return getBlobEncode(blobCaesar)
// }
//
// export async function getBlobCaesarDecodeContent (blob) {
//   const { mixed } = store.state.coder
//   const blobCaesar = await getBlobDecode(blob)
//   const textCaesar = await blobCaesar.text()
//   return textCaesar.split('')
//     .map(word => word.charCodeAt(0) - mixed)
//     .map(code => String.fromCharCode(code))
//     .join('')
// }
