import VConsole from 'vconsole'
import store from '@/store'

class Manager {
  constructor () {
    this.instance = null
    this.update()
  }

  // 根据 store 更新
  update () {
    if (store.state.isDebug && !this.instance) {
      this.instance = new VConsole()
    }
    if (!store.state.isDebug && this.instance) {
      this.instance.destroy()
      this.instance = null
    }
  }

  change () {
    store.commit('isDebugToggle')
    this.update()
  }
}

const manager = new Manager()

export const change = () => manager.change()
