function normalizeSize (data) {
  if (tools.isArray(data)) {
    return data
  }
  if (tools.isObjectLike(data)) {
    const { width, height } = data
    return [width, height]
  }
  return []
}

const aspect = {
  name: 'aspect',
  async inserted (element, { value }) {
    const [width, height] = normalizeSize(value)
    if (width && height) {
      element.style.aspectRatio = `${width} / ${height}`
    }
  }
}

export default aspect
