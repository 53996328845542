import request from '@/utils/request'
import { getBlobDecode } from '@/utils/hash'
import { getSafeImageUrl } from '@/utils/safe'
import { getByMemoryCache, getByIDBCache } from '@/utils/cache'

// options.src 原始url
// modifiers.lazy 延迟加载
// modifiers.src   设置src属性
// modifiers.css   设置背景
// data-order 权重
// v-uri-loaded 标记加载完成

class Parser {
  // 监听
  async observer (element, src, modifiers) {
    if (element.__uri_value__ === src) {
      return true
    }
    element.__uri_value__ = src
    // 懒加载
    if (modifiers.lazy) {
      // 监听可见
      const io = new IntersectionObserver(async ([entry]) => {
        if (entry.isIntersecting) {
          await this.setElementUrl(element, src, modifiers)
          // 解除监听
          io.disconnect()
        }
      })
      // 开始监听
      io.observe(element)
      // 解除监听
      return () => io.disconnect()
    }
    // 赋值
    await this.setElementUrl(element, src, modifiers)
    return true
  }

  async setElementUrl (element, src, modifiers) {
    const order = tools.toNumber(element.getAttribute('data-order'))
    const url = getSafeImageUrl() || await this.getObjectUrl(src, order)

    if (modifiers.src) {
      element.setAttribute('src', url)
    }
    if (modifiers.css) {
      element.style.backgroundImage = `url(${url})`
    }
    element.setAttribute('v-uri-loaded', true)
    tools.dispatchHTMLEvent('on-uri-loaded', element, url)
  }

  async getObjectUrl (src, order) {
    // await tools.delay(tools.faker.number(0, 80000))
    return getByMemoryCache({
      name: 'uri_url:' + src,
      getValue: async () => {
        const blob = await getByIDBCache({
          name: 'uri_blob:' + src,
          getValue: () => {
            return request.get(src, { blob: true, toast: false, order })
          }
        })
        const decode = await getBlobDecode(blob)
        return URL.createObjectURL(decode)
      }
    })
  }
}

const parser = new Parser()

const uri = {
  name: 'uri',
  async inserted (element, { value, modifiers }) {
    if (value) {
      element.__disconnect__ = await parser.observer(element, value, modifiers)
    }
  },
  async update (element, args) {
    return uri.inserted(element, args)
  },
  unbind (element) {
    if (element.__disconnect__ instanceof Function) {
      element.__disconnect__()
    }
  }
}

export default uri
