import axios from 'axios'
import { enc } from 'crypto-js'
import store from '@/store'

export function setAuthorization (token) {
  store.commit('setToken', token)
}

export function getAuthorization () {
  return 'Bearer ' + store.state.token
}

function parseJwt () {
  try {
    if (store.state.token) {
      const words = enc.Base64.parse(store.state.token.split('.')[1])
      const raw = enc.Utf8.stringify(words)
      return tools.jsonParse(raw)
    }
  } catch (e) {
    console.error(e)
  }
}

function refreshJwt () {
  axios.post('/api/user/refresh', null, {
    headers: {
      authorization: getAuthorization()
    }
  }).then(response => {
    setAuthorization(response.data.token)
  })
}

// 获取有效时长，分钟
export function isEffectiveJwt () {
  const data = parseJwt()
  const minutes = data && tools.dayjs(data.exp * 1000).diff(tools.dayjs(), 'm')
  // 20分钟刷新
  if (minutes && minutes > 0 && minutes <= 20) {
    refreshJwt()
  }
  return minutes && minutes > 0
}
