import store from '@/store'
import PopConfirm from '@/components/pop/PopConfirm.vue'

const offset = 5

function isSafeMode () {
  return store.state.safeMode
}
function getSafeEncoder (data) {
  if (tools.isObjectLike(data)) {
    return Object.entries(data).reduce((obj, [name, value]) => {
      return { ...obj, [name]: getSafeEncoder(value) }
    }, {})
  }
  if (tools.isValueArray(data)) {
    return data.map(data => getSafeEncoder(data))
  }
  // 包含中文时，替换
  if (tools.isString(data) && data.match(/[\u4e00-\u9fa5]/)) {
    return data.replace(/[\u4e00-\u9fa5]/ig, (value) => String.fromCharCode(value.charCodeAt(0) + offset))
  }
  return data
}

export function getSafeImageUrl (url) {
  if (isSafeMode()) {
    return location.origin + '/static/img/safe.png'
  }
  return url
}

export function getSafeMediaUrl (url) {
  if (isSafeMode()) {
    return 'https://liber-bucket.vmail.cc/safe.m3u8'
  }
  return url
}

export async function onSafeSubmit (data) {
  if (isSafeMode() && data && JSON.stringify(data) !== JSON.stringify(getSafeEncoder(data))) {
    await uif.pop(PopConfirm, {
      message: '安全模式需要确认提交的中文内容：\n' + JSON.stringify(data)
    })
  }
}

export function getSafeResponse (data) {
  return isSafeMode() ? getSafeEncoder(data) : data
}
