import aspect from './aspect'
import m3u8 from './m3u8'
import uri from './uri'

export default {
  install: (Vue) => {
    Vue.directive(aspect.name, aspect)
    Vue.directive(m3u8.name, m3u8)
    Vue.directive(uri.name, uri)
  }
}
