import Vue from './vue'
import App from './App'
import store from './store'
import router from './router'
import directives from './directives'
import './style.scss'

Vue.use(directives)

Vue.config.productionTip = false
window.$root = new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
