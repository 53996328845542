const memory = new Map()
function memoryMiddleware ({ name, expire, force }, next) {
  let record = null
  const update = new Date().getTime()
  // 读取缓存
  if (!force) {
    record = memory.get(name)
  }
  // 已过期
  if (record && expire && record.update + expire * 1000 < update) {
    record = null
  }
  if (!record) {
    record = { update, data: next() }
    memory.set(name, record)
  }
  return record.data
}

const idb = new tools.IndexedDB('liber_cache', {
  version: 1,
  storeNames: ['content']
})
async function idbMiddleware ({ name, expire, force }, next) {
  let record = null
  // 读取缓存
  if (!force) {
    record = await idb.getStore({
      storeName: 'content',
      id: name,
      options: { expire }
    })
  }
  if (!record) {
    record = await next()
    idb.setStore({
      storeName: 'content',
      id: name,
      options: { expire },
      data: record
    }).then(() => 0)
  }
  return record
}

/**
 @param name                {String}                缓存键
 @param getValue            {Function}              函数
 @param expire              {Number=}               过期时间，秒
 @param force               {Boolean=}              强制更新
 @param memory              {Boolean=}              内存缓存
 @param idb                 {Boolean=}              IndexedDB 缓存
 * **/
async function getByCache ({ name, getValue, expire, force, memory, idb }) {
  const mm = new tools.MiddlewareManager()

  // 内存缓存
  memory && mm.use(memoryMiddleware)
  // IndexedDB 缓存
  idb && mm.use(idbMiddleware)

  mm.use(() => getValue())
  return mm.$execute({ name, expire, force }, 0)
}

/**
 * 内存缓存
 @param name                {String}               缓存键
 @param getValue            {Function}             函数
 @param expire              {Number=}              过期时间，秒
 @param force               {Boolean=}             强制更新
 * **/
export async function getByMemoryCache ({ name, getValue, expire, force }) {
  return getByCache({ name, getValue, expire, force, memory: true })
}

/**
 * IndexedDB 缓存
 @param name                {String}               缓存键
 @param getValue            {Function}             函数
 @param expire              {Number=}              过期时间，秒
 @param force               {Boolean=}             强制更新
 * **/
export async function getByIDBCache ({ name, getValue, expire, force }) {
  return getByCache({ name, getValue, expire, force, memory: true, idb: true })
}
