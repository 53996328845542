<template>
  <n-popup class="n-popup-confirm" v-model="visible" width="auto" title="Message" confirm-text="Confirm" cancel-text="Cancel"
           v-bind="{ onConfirm, onCancel }" @success="$emit('success')">
    <div class="n-popup-confirm-content">{{ message }}</div>
  </n-popup>
</template>

<script>
export default {
  name: 'PopConfirm',
  props: {
    // 提示内容
    message: tools.prop.string('Are you sure to delete it?'),
    // 确认按钮回调事件
    onConfirm: tools.prop.function(),
    // 取消按钮回调事件
    onCancel: tools.prop.function()
  },
  data () {
    return {
      visible: false
    }
  },
  mounted () {
    this.visible = true
  }
}
</script>
