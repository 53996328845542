import Vue from 'vue'
import Router from 'vue-router'
import { isEffectiveJwt } from '@/utils/jwt'

Vue.use(Router)

const router = new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: {
        name: 'home'
      }
    },
    {
      meta: {
        public: true
      },
      path: '/login',
      name: 'login',
      component: () => import('@/views/userLogin')
    },
    {
      meta: {
        public: true
      },
      path: '/register',
      name: 'register',
      component: () => import('@/views/userRegister')
    },
    {
      path: '_',
      name: 'appContainer',
      component: () => import('@/views/appContainer'),
      children: [
        {
          path: '/home',
          name: 'home',
          component: () => import('@/views/home')
        },
        {
          path: '/album',
          name: 'album',
          component: () => import('@/views/album')
        },
        {
          path: '/albumGallery/:id',
          name: 'albumGallery',
          component: () => import('@/views/albumGallery')
        },
        {
          path: '/albumSubject/:id',
          name: 'albumSubject',
          component: () => import('@/views/albumSubject')
        },
        {
          path: '/media',
          name: 'media',
          component: () => import('@/views/media')
        },
        {
          path: '/mediaGallery/:id',
          name: 'mediaGallery',
          component: () => import('@/views/mediaGallery')
        },
        {
          path: '/mediaSubject/:id',
          name: 'mediaSubject',
          component: () => import('@/views/mediaSubject')
        },
        {
          path: '/novel',
          name: 'novel',
          component: () => import('@/views/novel')
        },
        {
          path: '/novelSubject/:id',
          name: 'novelSubject',
          component: () => import('@/views/novelSubject')
        },
        {
          path: '/novelContent/:subjectId/:chapter',
          name: 'novelContent',
          component: () => import('@/views/novelContent')
        },
        {
          path: '/prompt',
          name: 'prompt',
          component: () => import('@/views/prompt')
        },
        {
          path: '/tag',
          name: 'tag',
          component: () => import('@/views/tag')
        },
        {
          path: '/setting',
          name: 'setting',
          component: () => import('@/views/setting')
        },
        {
          path: '/404',
          name: '404',
          component: () => import('@/views/not-fount')
        }
      ]
    },
    {
      path: '*',
      redirect: {
        name: '404'
      }
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  if (!to.meta?.public && !isEffectiveJwt()) {
    return next('login')
  }
  next()
})

export default router
