import axios from 'axios'
import store from '@/store'
import { getAuthorization, isEffectiveJwt } from '@/utils/jwt'
import { getSafeResponse, onSafeSubmit } from '@/utils/safe'
import { getByMemoryCache } from '@/utils/cache'
import getPool from '@/utils/getPool'

const pool = getPool(3)
const time = new tools.Timeout()

// 转成 FormData
function toFormData (data) {
  const formData = new FormData()
  Object.entries(data).forEach(([name, value]) => {
    if (value instanceof Blob) {
      return formData.append(name, value, value.name)
    }
    formData.append(name, value)
  })
  return formData
}

const singleCaches = new Set()

// single: true, blob: true, toast: false
async function http (method, url, data, options) {
  if (options?.single) {
    if (singleCaches.has(url)) {
      return new Promise(() => {})
    }
    singleCaches.add(url)
  }

  time.out(() => {
    store.commit('setLoading', options?.loading !== false)
    if (options?.message) {
      store.commit('setMessage', options.message)
    }
  }, 0)

  const config = {
    method,
    url,
    data,
    withCredentials: false,
    // baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {}
  }
  // 参数中包含 Blob | File
  if (data && Object.values(data).some(value => value instanceof Blob)) {
    config.data = toFormData(data)
  }
  if (isEffectiveJwt()) {
    config.headers.authorization = getAuthorization()
  }
  // 文件
  if (options?.blob) {
    config.responseType = 'blob'
  }
  // 超时
  if (options?.timeout) {
    config.timeout = options.timeout
  }
  await onSafeSubmit(data)
  // console.log('axios send: ' + url)
  const response = await axios(config)
    .catch(error => {
      if (options?.toast !== false) {
        uif.toast.error(error.response?.data || error.message)
      }
      return Promise.reject(error)
    })
    .finally(() => {
      time.out(() => {
        store.commit('setLoading', false)
        store.commit('setMessage', '')
      }, 1000)
      options?.single && singleCaches.delete(url)
    })

  if (response?.status === 200) {
    return getSafeResponse(response.data)
  }
  // eslint-disable-next-line prefer-promise-reject-errors
  return Promise.reject()
}

// force: true, cache: true, order: 1
function send (method, url, data, options) {
  const order = options?.order
  const task = () => http(method, url, data, options)

  if (options?.cache) {
    return getByMemoryCache({
      name: [method, url].join(':'),
      getValue: () => pool({ task, order }),
      force: options?.force
    })
  }
  return pool({ task, order })
}

export default {
  get: (url, options) => send('GET', url, null, options),
  post: (url, data, options) => send('POST', url, data, options),
  put: (url, data, options) => send('PUT', url, data, options),
  delete: (url, options) => send('DELETE', url, null, options)
}
