import { getByMemoryCache } from '@/utils/cache'
import { getSafeMediaUrl } from '@/utils/safe'
import request from '@/utils/request'

// 获取本地库
export async function getSafeLocalServerUrl (url) {
  const ping = await getByMemoryCache({
    name: 'm3u8_active_ip',
    getValue: () => request.get('http://127.0.0.1:100/ping.json?3', { toast: false, loading: false, timeout: 3000 }).catch(() => 0)
  })
  // https://liber-media-dev.vmail.cc/media/421b8fc7e1e54315504860f753ac28cb/index.m3u8?e=1724768374&token=TjZwfb38nKptqsGGNTqMvyk5mIZPOSp0HHJtF50o:OVo1iMkVJ_o2X4chlQ2ovS3VNrk=
  const hash = url.split('/').at(-2)
  return getSafeMediaUrl() || ping?.m3u8?.replaceAll('{{hash}}', hash) || url
}

getSafeLocalServerUrl('').catch(() => 0)

export default {
  name: 'm3u8',
  async inserted (el, { value: url, modifiers }) {
    if (url) {
      // 获取URL
      const data = JSON.stringify({
        source: [{
          src: await getSafeLocalServerUrl(url),
          type: 'application/x-mpegURL'
        }],
        attributes: {
          preload: 'none',
          playsinline: true,
          controls: true
        }
      })
      el.setAttribute('data-video', data)
      tools.dispatchHTMLEvent('on-m3u8-loaded', el)
    }
  }
}
