// 利用 Promise 实现请求池效果。
function getPool (count = 3) {
  const queue = [] // 请求池
  let current = 0
  const dequeue = () => {
    if (current < count && queue.length) {
      current++
      // 获取并立即执行队列中的下一个任务
      const { task, state } = queue.shift()
      task()
        .then((arg) => state.resolve(arg))
        .catch((err) => state.reject(err))
        .finally(() => {
          current--
          // 无论成功或失败，都继续处理队列中的下一个任务
          dequeue()
        })
    }
  }
  // 入列，这里push 回调函数
  return ({ task, order = 0 }) => {
    const state = tools.promisify()
    // 按 order 正序进行排序
    let index = queue.findIndex(item => item.order > order)
    index = index >= 0 ? index : queue.length
    queue.splice(index, 0, { task, state, order })
    dequeue()
    return state.promise
  }
}

export default getPool
